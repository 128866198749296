import getConfig from 'next/config';
import firebase from "firebase/app";
import 'firebase/auth';

const {firebaseConfig} = getConfig().publicRuntimeConfig;

try {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
} catch (err) {
  console.error('Firebase initialization error', err.stack);
}

export default firebase;
